import React from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";
import useForm from "../hooks/useForm";
import { getInput } from "./Inputs";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Form = styled.form`
  ${tw`w-full`}
`;
const GridContainer = styled.div`
  ${tw`grid grid-cols-12 gap-2.5`}
`;
const GridItem = styled.div`
  grid-column: ${(props) => `span ${props.span} / span ${props.span}`};
`;
const SubmitButton = styled.button`
  ${tw`px-4 py-2 mt-1 text-white font-bold rounded text-sm disabled:grayscale transition-[filter] duration-200`}
  background-color: ${(props) => props.color || "#000000"};
`;

const ContactForm = ({ color = "#000000" }) => {
  const {
    handleChange,
    handleSubmit,
    submitted,
    error,
    loading,
    errorMessage,
  } = useForm({});
  const breakpoints = useBreakpoint();
  const isMidScreen =
    Object.values(breakpoints).reduce((a, b) => (b ? a + 1 : a), 0) > 1;
  return (
    <Form name="contact-form" onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem span={isMidScreen ? 6 : 12}>
          {getInput({
            handleChange,
            inputType: "text",
            label: "name",
            required: true,
            color,
          })}
        </GridItem>
        <GridItem span={isMidScreen ? 6 : 12}>
          {getInput({
            handleChange,
            inputType: "phone",
            label: "phone",
            required: true,
            color,
          })}
        </GridItem>
        <GridItem span={12}>
          {getInput({
            handleChange,
            inputType: "email",
            label: "email",
            required: true,
            color,
          })}
        </GridItem>
        <GridItem span={12}>
          {getInput({
            handleChange,
            inputType: "textarea",
            label: "message",
            required: true,
            color,
          })}
        </GridItem>
        <GridItem span={12} tw="flex items-center justify-between">
          <SubmitButton disabled={loading || submitted} color={color}>
            Send Message
          </SubmitButton>
          {error && <span style={{ color: color }}>{errorMessage}</span>}
          {submitted && (
            <span tw="text-black">{"Message successfully sent!"}</span>
          )}
        </GridItem>
      </GridContainer>
    </Form>
  );
};

export default ContactForm;
