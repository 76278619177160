import { useState } from "react";
import _ from "lodash";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const useForm = ({ initForm }) => {
  const [form, setForm] = useState(initForm);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formElement = event.target;
    if (typeof window === "undefined") {
      return;
    }
    setLoading(true);
    try {
      const res = await fetch(
        "https://us-central1-lt3-sendgrid-mailer.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: formElement.getAttribute("name"),
            ...form,
          }),
        }
      );
      const resMessage = await res.text();
      setErrorMessage(resMessage);

      setSubmitted(res.ok);
      setError(!res.ok);
      if (res.ok) {
        setForm({ initForm });
        setErrorMessage("");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("Something went wrong, please try again.");
    }

    setLoading(false);
  };

  return {
    form,
    handleChange,
    handleSubmit,
    submitted,
    loading,
    error,
    errorMessage,
  };
};

export default useForm;
