import React from "react";
import tw, { styled } from "twin.macro";
import _ from "lodash";

const InputWrapper = styled.div`
  ${tw`w-full relative`}
`;
const InputLabel = styled.label`
  ${tw`text-[10px] px-4 absolute top-2 font-bold text-gray-400 uppercase`}
`;
const StyledInput = styled.input`
  ${tw`w-full outline-0 pt-6 px-4 pb-1.5 text-sm border border-gray-300 rounded focus:invalid:border`}
  &:focus:invalid {
    border-color: ${(props) => props.color};
  }
`;
const StyledInputArea = styled.textarea`
  ${tw`w-full outline-0 pt-6 px-4 pb-1.5 text-sm border border-gray-300 rounded focus:invalid:border`}
  &:focus:invalid {
    border-color: ${(props) => props.color};
  }
`;

export const TextInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
  color,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledInput
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
      color={color}
    />
  </InputWrapper>
);

export const PhoneInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
  color,
}) => {
  return (
    <InputWrapper>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <StyledInput
        required={required}
        name={name}
        onChange={handleChange}
        id={label}
        placeholder={placeholder}
        type="tel"
        pattern="[(\[][0-9]{3}[)\]]-[0-9]{3}-[0-9]{4}"
        color={color}
      />
    </InputWrapper>
  );
};

export const EmailInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
  color,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledInput
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
      type="email"
      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
      color={color}
    />
  </InputWrapper>
);

export const DateInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
  color,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledInput
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
      type="date"
      pattern="\d{4}-\d{2}-\d{2}"
      color={color}
    />
  </InputWrapper>
);

export const TextAreaInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
  color,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledInputArea
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
      color={color}
    />
  </InputWrapper>
);

const StyledSelect = styled.select`
  ${tw`w-full outline-0 pt-6 px-4 pb-1.5 text-sm border border-gray-300 rounded focus:invalid:border`}
  &:focus:invalid {
    border-color: ${(props) => props.color};
  }
`;

export const SelectInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
  options,
  color,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledSelect
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
      color={color}
    >
      <option value="">_</option>
      {!_.isEmpty(options) &&
        options.map((op, idx) => (
          <option key={idx} value={op}>
            {op}
          </option>
        ))}
    </StyledSelect>
  </InputWrapper>
);

export const getInput = ({
  handleChange,
  inputType,
  label,
  options,
  placeholder,
  required,
  color,
}) => {
  const name = _.kebabCase(label);

  switch (inputType) {
    case "text":
      return (
        <TextInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
          color={color}
        />
      );
    case "textarea":
      return (
        <TextAreaInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
          color={color}
        />
      );
    case "select":
      return (
        <SelectInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
          options={options}
          color={color}
        />
      );
    case "date":
      return (
        <DateInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
          color={color}
        />
      );
    case "email":
      return (
        <EmailInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
          color={color}
        />
      );
    case "phone":
      return (
        <PhoneInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
          color={color}
        />
      );
    default:
      return <div />;
  }
};
